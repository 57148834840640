<template>
    <div v-if="query != null">
        <BiaCampaignComponent :campaignQuery="query"/>
    </div>
</template>

<script>
import "../Global.css";
import BiaCampaignComponent from "../components/BiaCampaingComponent.vue"
import BiaService from "../service/BiaService.js"

export default {
  components: {
    BiaCampaignComponent,
  },
  data() {
    return {
      query: null,
      id: null,
    };
  },
  async created() {
    if (this.$route.query.id != undefined) {    
        this.id = this.$route.query.id
    } else{
        this.$router.push({ name: 'bia' });
    }

    await BiaService.getCampaignProcess(this.id).then((response) => {
        this.query = response.data
    })

  },
  mounted(){
        


  },
  methods: {},
};
</script>
<style>
</style>